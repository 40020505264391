<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'employee' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="12">
          <user-view-user-timeline-card
            :evaluation-list="userData.evaluationList"
          />
        </b-col>
      </b-row>

      <b-row v-if="currentRole.type === 'admin'">
        <b-col cols="12" lg="12">
          <user-view-document :history-document="userData.historyDocument" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
// import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewDocument from "./UserViewDocument.vue";
import { employeeService } from "@/services/employee";
import { decodeToken } from "@core/utils/decodeJwt.js";
import { constructUrlImage } from "@/libs/helper";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserTimelineCard,
    UserViewDocument,
  },
  setup() {
    const userData = ref(null);
    const currentRole = decodeToken();
    const id = router.currentRoute.params.id;

    employeeService
      .getData(id)
      .then((response) => {
        if (response.status) {
          userData.value = response.data;
          const imageURL = constructUrlImage(response.data.avatar);
          userData.value.avatar = imageURL;
        }
      })
      .catch((error) => {
        userData.value = undefined;
      });

    return {
      userData,
      currentRole,
    };
  },
};
</script>

<style></style>
