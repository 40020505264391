<template>
  <b-card :title="$t('history_document')">
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <!-- <app-timeline-item>
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6>12 Invoices have been paid</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Invoices have been paid to the company.</p>
        <p class="mb-0">
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">invoice.pdf</span>
        </p>
      </app-timeline-item> -->
      <app-timeline-item
        v-for="document in historyDocument"
        :key="document._id"
        variant="success"
      >
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <div>
            <b-img
              :src="require('@/assets/images/icons/pdf.png')"
              height="auto"
              width="14"
              class="mr-1"
            />

            <a
              :href="`${`${$apiURL}/files/${document.fileName}`}`"
              class="align-bottom"
              target="_blank"
            >
              {{ document.fileName }}
            </a>
          </div>
          <small class="text-muted">
            {{ dateTimeToStr(document.createdAt) }}
          </small>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {
  resolveEvaluationStatus,
  dateTimeToStr,
  mappingEvaluationStatusText,
} from "@/libs/helper";
export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    historyDocument: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      resolveEvaluationStatus,
      dateTimeToStr,
      mappingEvaluationStatusText,
    };
  },
};
</script>

<style></style>
