<template>
  <b-card no-body class="mb-1" v-if="userData">
    <!-- <b-card-header class="pt-3 d-flex justify-content-center">
      <h2>{{ $t("add_employee") }}</h2>
    </b-card-header> -->
    <b-row class="m-2 pt-2">
      <b-col cols="12" md="3">
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            rounded="bottom"
            variant="light-dark"
            size="168"
            ref="previewEl"
            :src="userData.avatar"
          />

          <b-row>
            <router-link
              :to="{ name: 'edit-employee', params: { id: userData._id } }"
            >
              <b-button variant="primary" type="submit" class="mt-1">
                {{ $t("edit_data") }}
              </b-button>
            </router-link>
            <router-link :to="{ name: 'report' }">
              <b-button variant="danger" type="submit" class="mt-1 ml-1">
                {{ $t("notify_leave") }}
              </b-button>
            </router-link>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" md="8">
        <table class="mt-2 mt-xl-0 w-100">
          <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">User ID</span>
            </th>
            <td class="pb-50">
              {{ userData._id }}
            </td>
          </tr> -->
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("full_name") }}</span>
            </th>
            <td class="pb-50">
              {{ userData.firstName }}
              {{ userData.lastName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("department") }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.department ? userData.department.name : "-" }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CreditCardIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("national_id") }}</span>
            </th>
            <td class="pb-50">
              {{ userData.nationalID }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">{{ $t("status") }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <span
                class="bullet bullet-sm"
                :class="`bullet-${mappingStatusColor(userData.status)}`"
              />
              <span>
                {{ mappingStatusText(userData.status) }}
              </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FileTextIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t("upload_national_id")
              }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <div v-if="userData.nationalFile">
                <b-img
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="14"
                  class="mr-1"
                />

                <a
                  :href="`${
                    userData.nationalFile
                      ? `${$apiURL}/files/${userData.nationalFile.fileName}`
                      : '#'
                  }`"
                  class="align-bottom"
                  target="_blank"
                >
                  {{
                    userData.nationalFile ? userData.nationalFile.fileName : "-"
                  }}
                </a>
              </div>
              <div v-else>
                <span style="color: red">ยังไม่อัพโหลดเอกสาร</span>
              </div>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FileTextIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t("upload_registration")
              }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <div v-if="userData.registrationFile">
                <b-img
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="14"
                  class="mr-1"
                />

                <a
                  :href="`${
                    userData.registrationFile
                      ? `${$apiURL}/files/${userData.registrationFile.fileName}`
                      : '#'
                  }`"
                  class="align-bottom"
                  target="_blank"
                >
                  {{
                    userData.registrationFile
                      ? userData.registrationFile.fileName
                      : "-"
                  }}
                </a>
              </div>
              <div v-else>
                <span style="color: red">ยังไม่อัพโหลดเอกสาร</span>
              </div>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BFormInput,
  BFormInvalidFeedback,
  BFormGroup,
  BCardHeader,
  BForm,
  BFormFile,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
} from "bootstrap-vue";
import { mappingStatusColor, mappingStatusText } from "@/libs/helper";
export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BAvatar,
    BForm,
    BFormFile,
    BFormInvalidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      mappingStatusColor,
      mappingStatusText,
    };
  },
};
</script>

<style></style>
